#numbers-output-container {
    font-family: 'PT Mono', monospace;
    width: 82%;
    margin-left: 16%;
    margin-top: 2%;
    background-color: white;
    padding: 1% 0%;
  }
  
  @media (max-width: 899px) {
    #numbers-output-container {
      width: 90%;
      margin-left: 5%;
    }
  }

  #numbers-output-container-full-width {
    @extend #numbers-output-container;
    width: 92%;
    margin-left: 6%;
  }
  
  @media (min-width: 1201px) and (max-width: 1536px) {
    #numbers-output-container-full-width {
    width: 90%;
    margin-left: 8%;
   }
  }
  
  @media (min-width: 900px) and (max-width: 1200px) {
    #numbers-output-container-full-width {
    width: 88%;
    margin-left: 10%;
    }
  }

  @media (max-width: 899px) {
    #numbers-output-container-full-width {
      width: 90%;
      margin: 2% auto;
    }
  }

  #numbers-output-table-container {
    width: 98%;
    padding: 0% 1%;
    margin-left: 1%;
  }

  .numbers-output-table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }
  
  .numbers-output-table-header {
    background-color: #D6D6D6;
    text-align: left;
    border-bottom: 4px solid black;
  }

  .numbers-output-table-header-desc {
    padding: 1%;
  }

  .table-description-item {
    font-weight: 500;
    padding: 1%;
    text-align: left;
  }
  
  @media (max-width: 624px) {
    .table-description-item {
      white-space: wrap;
    }
  }
  
  @media (max-width: 424px) {
    .table-description-item {
      font-size: 10px;
    }
  }

  .table-row-item-regular {
    border-bottom: lightgray 1px solid;
  }
  
  @media (max-width: 424px) {
    .table-row-item-regular {
      font-size: 10px;
    }
  }
  
  .table-na-section {
    color: red;
  }
  
  @media (max-width: 424px) {
    .table-na-section {
      font-size: 10px;
    }
  }
  
  .table-total-row {
    font-weight: bolder;
    border-top: 4px solid black;
    background-color: #f9f9f9;
  }
  
  .table-total-row th {
    background-color: #f9f9f9;
    padding: 1%;
    text-align: left;
  }

  .table-total-row td {
    background-color: #f9f9f9;
  }
  
  .table-spacing-row td {
    padding-top: 25px; /* Adjust the value to the space you need */
    border: none;
    line-height: 0; /* To minimize the impact of line height on the padding */
    font-size: 0; /* To minimize the impact of font size on the padding */
  }
  
  @media (min-width: 425px) and (max-width: 624px) {
    .table-total-row {
      white-space: wrap;
    }
  }
  
  @media (max-width: 424px) {
    .table-total-row {
      white-space: wrap;
      font-size: 10px;
    }
  }
  
  #table-safe-harbor-row {
    background-color: lighten(#1976d2, 45%);
  }
  
  #table-minimum-row {
    background-color: lighten(#28a745, 40%);
  }
  
  .numbers-output-with-tax-box-title {
    font-weight: bold;
    font-size: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2%;
  }
  
  .numbers-output-with-tax-box-title span {
    text-align: center;
    flex-grow: 1;
  }
  
  .toggle-hide-rows-label {
    display: flex;
    align-items: right;
    font-size: 14px;
  }
  
  @media (max-width: 624px) {
    .toggle-hide-rows-label {
      font-size: 12px;
    }
  }
  
  .toggle-hide-rows-checkbox {
    cursor: pointer;
    width: 30px;
  }
  
  @media (max-width: 624px) {
    .toggle-hide-rows-checkbox {
      width: 25px;
    }
  }
  
  .export-footer-container {
    display: flex;
    justify-content: flex-end;
    padding: 2% 4% 1% 0%;
  }
  
  @media (min-width: 625px) and (max-width: 964px) {
    .export-footer-container {
      padding: 4% 8% 2% 0%;
    }
  }
  
  @media (max-width: 624px) {
    .export-footer-container {
     padding: 5% 0% 3% 0%;
      justify-content: center;
    }
  }
  
  .export-footer-wrapper {
    display: flex;
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 20%;
  }
  
  .export-footer-icon-container {
    font-size: 180%;
  }
  
  .export-footer-icon-container button {
    cursor: pointer;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    outline: inherit;
    transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
  }
  
  .export-footer-icon-container button:hover {
    transform: scale(1.1);
    color: #3f59e4;
  }