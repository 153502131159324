@media print {
  body * {
    visibility: hidden;
  }
  #printableArea,
  #printableArea * {
    visibility: visible;
  }
  #printableArea {
    position: absolute;
    left: 0;
    top: 0;
  }
}
