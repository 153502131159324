  .header-anchor {
    text-decoration: none;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 6px 8px;
    // transition options do not work with anchor tags
    // transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    // transition-property: background-color, box-shadow, border-color, color;
    // transition-duration: 250ms, 250ms, 250ms, 250ms;
    // transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1);
    // transition-delay: 0ms, 0ms, 0ms, 0ms;
    border-radius: 4px;
    transition: width 2s linear 1s;
    color: rgb(255, 255, 255);
    font-size: 130%;
  }
  
  .header-anchor:hover {
    color: white;
    background-color: rgba(255, 255, 255, 0.5);
    opacity: 0.7;
    cursor: pointer;
  }

  .header-list-anchor-container {
    display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
  }
  
  .header-list-anchor {
    display: flex;
    font-size: 103%;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    border-radius: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    color: inherit;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    text-decoration: none;
    min-width: 0px;
    box-sizing: border-box;
    padding: 8px 16px;
    // transition options do not work with anchor tags
    // transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    // transition-property: background-color;
    // transition-duration: 150ms;
    // transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    // transition-delay: 0ms;
  }
  
  .header-list-anchor:hover {
    color: black;
    background-color: #f4f0ec;
    opacity: 0.8;
  }

  .try-now-button {
    text-decoration: none;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    outline: 0px;
    margin: 0px 15px 0px 0px;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 130%;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    background-color: #f0ad4e;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    font-weight: bold;
    text-transform: uppercase;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
    transform: translateZ(0);
}

.try-now-button:hover {
  background-color: #ec971f; /* Darker shade on hover for effect */
  cursor: pointer; /* Change cursor to indicate clickability */
  transform: scale(1.05) translateZ(0); /* Scale up the button a bit */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); /* Shadow effect for depth */
}