#taxnotes-container {
  font-family: 'Source Sans 3', sans-serif;
  padding-bottom: 2%;
  width: 82%;
  margin: 5% 0 3% 16%;
}
  
@media (min-width: 1165px) and (max-width: 1624px) {
  #taxnotes-container {
    margin-top: 7%;
  }
}
  
@media (max-width: 899px) {
  #taxnotes-container {
    width: 90%;
    margin-left: 5%;
  }
}
  
@media (min-width: 765px) and (max-width: 1164px) {
  #taxnotes-container {
    margin-top: 9%;
    margin-bottom: 5%;
  }
}
  
@media (min-width: 601px) and (max-width: 764px) {
  #taxnotes-container {
    margin-top: 10%;
    margin-bottom: 10%;
  }
}
  
@media (min-width: 425px) and (max-width: 600px) {
  #taxnotes-container {
    margin-top: 12%;
    margin-bottom: 12%;
  }
}
  
@media (max-width: 424px) {
  #taxnotes-container {
    margin-top: 16%;
    margin-bottom: 16%;
  }
}
  
#taxnotes-container-full-width {
  @extend #taxnotes-container;
  width: 92%;
  margin-left: 6%;
}

@media (min-width: 1201px) and (max-width: 1536px) {
  #taxnotes-container-full-width {
    width: 90%;
    margin-left: 8%;
   }
}
  
@media (min-width: 900px) and (max-width: 1200px) {
  #taxnotes-container-full-width {
    width: 88%;
    margin-left: 10%;
  }
}

@media (max-width: 899px) {
  #taxnotes-container-full-width {
    width: 90%;
    margin: 2% auto;
  }
}

  
@media (min-width: 765px) and (max-width: 1164px) {
  #taxnotes-container-full-width {
    margin-top: 9%;
    margin-bottom: 5%;
  }
}
  
@media (min-width: 601px) and (max-width: 764px) {
  #taxnotes-container-full-width {
    margin-top: 14%;
    margin-bottom: 10%;
  }
}
  
@media (min-width: 425px) and (max-width: 600px) {
  #taxnotes-container-full-width {
    margin-top: 12%;
    margin-bottom: 12%;
  }
}
  
@media (max-width: 424px) {
  #taxnotes-container-full-width {
    margin-top: 16%;
    margin-bottom: 16%;
  }
}
  
#taxnotes-title {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 28px;
  text-align: center;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.1);
  padding: 5px 0px;
}
  
#taxnotes-textarea {
  height: 440px;
  width: 100%;
  font-size: 16px;
  background-color: #f8f8f8;
  border-radius: 5px; 
  border: 2px solid #007bff;
  box-sizing: border-box;
  box-shadow: 0px 10px 10px -7px rgba(0, 0, 0, 0.5);
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out; 
}

#taxnotes-textarea:focus {
  outline: none;
  border-color: #0056b3;
  box-shadow: 0 0 8px 0 rgba(0, 123, 255, 0.5);
}
  
@media (max-width: 1624px) {
  #taxnotes-textarea {
    height: 420px;
  }
}