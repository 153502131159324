.box-title {
  font-family: 'Poppins', sans-serif;
  text-align: center;
  font-weight: 700;
  font-size: 22px;
  margin-top: 1%;
}

.box-sub-title {
  text-align: center;
  font-style: italic;
  color: black;
  margin-bottom: 2.5%;
  margin-top: 0;
  line-height: 150%;
  font-size: 16px;
  font-weight: 500;
}

#numbers-input-box {
  font-family: 'Lato', sans-serif;
  width: 82%;
  margin-left: 16%;
  margin-top: 2%;
  border: 2px solid #dddddd;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow:
    0 1px 6px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  overflow: hidden;
  transition: box-shadow 0.3s ease;
}

#numbers-input-box:hover {
  box-shadow: 0px 4px 16px -3px rgba(0, 0, 0, 0.2);
}

@media (max-width: 899px) {
  #numbers-input-box {
    width: 90%;
    margin-left: 5%;
  }
}

#numbers-input-box-full-width {
  @extend #numbers-input-box;
  width: 92%;
  margin-left: 6%;
}

#numbers-input-box-full-width:hover {
  box-shadow: 0px 4px 16px -3px rgba(0, 0, 0, 0.2);
}

@media (min-width: 1201px) and (max-width: 1536px) {
  #numbers-input-box-full-width {
  width: 90%;
  margin-left: 8%;
 }
}

@media (min-width: 900px) and (max-width: 1200px) {
  #numbers-input-box-full-width {
  width: 88%;
  margin-left: 10%;
  }
}

@media (max-width: 899px) {
  #numbers-input-box-full-width {
    width: 90%;
    margin-left: 5%;
  }
}

.numbers-input-title {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  padding-left: 1%;
  font-size: 18px;
  margin-top: 30px;
}

.numbers-input-grid-container {
  display: grid;
  grid-template-columns: 4fr 10fr 1fr 1fr;
  grid-gap: 1px;
}

@media (min-width: 341px) and (max-width: 400px) {
  .numbers-input-grid-container {
    grid-template-columns: 3fr 6fr 0.5fr 1fr;
    grid-gap: 0.5px;
  }
}

@media (max-width: 340px) {
  .numbers-input-grid-container {
    grid-template-columns: 2fr 3fr 0.5fr 1fr;
    grid-gap: 0.5px;
  }
}

.list-group-container {
  padding-left: 0;
  margin: 0;
  list-style-type: none;

}

.list-group-item-color {
  background-color: #d6d6d6;
}

.numbers-input-grid-item {
  text-align: left;
  padding-right: 5%;
}

@media (max-width: 1024px) {
  .numbers-input-grid-item {
    text-align: right;
  }
}

.numbers-input-grid-item-flex {
  text-align: left;
  display: flex;
}

.numbers-list-element {
  color: black;
  font-weight: 500;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  padding: 7px 15px;
}

@media (min-width: 481px) and (max-width: 600px) {
  .numbers-list-element {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .numbers-list-element {
    font-size: 10px;
  }
}

#numbers-input-element-red {
  color: red;
}

.numbers-input-element {
  font-family: 'Nunito Sans', sans-serif;
  margin-left: 0;
  background-color: #fff;
  width: 50%;
  display: block;
  height: 20px;
  padding: 8px 8px;
  line-height: 10px;
  background-clip: padding-box;
  border: 1px solid #babec5;
  border-radius: 3px;
  box-shadow: 0;
  transition: none;
  font-size: 17px;
}

@media (max-width: 964px) {
  .numbers-input-element {
    width: 70%;
  }
}

.numbers-input-element:focus {
  border-color: #3f59e4;
  outline: 0;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
}

.annualize-input-button-example {
  font-style: normal;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.9em;
  width: 50px;
  height: 20px;
  border-radius: 10px;
  background-color: #28a745;
  padding: 0.3%;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.annualize-input-button-example.quarterly {
  background-color: #fd7e14;
}

.annualize-input-button {
  font-family: 'Open Sans', sans-serif;
  font-size: 0.9em;
  width: 50px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  padding: 25% 56%;
  border-radius: 10px;
  background-color: #28a745;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

@media (max-width: 1424px) {
  .annualize-input-button {
    padding: 30% 60%;
  }
}

@media (min-width: 425px) and (max-width: 600px) {
  .annualize-input-button {
    font-size: 13px;
  }
}

@media (max-width: 424px) {
  .annualize-input-button {
    padding: 28% 56%;
    font-size: 12px;
  }
}

.annualize-input-button.quarterly {
  background-color: #fd7e14;
}

.annualize-input-button:hover {
  opacity: 0.9;
}

.annualize-input-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(25, 118, 210, 0.5);
}

.annualize-input-button:active {
  opacity: 0.8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
}

#numbers-input-reset-button {
  margin-top: 2%;
  margin-right: 1%;
}
