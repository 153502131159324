#tax-assumptions-box {
    width: 82%;
    margin-left: 16%;
    margin-top: 2%;
    background-color: #ffffff;
    align-items: center;
    text-align: center;
    border: 2px solid #dddddd;
    border-bottom: 4px solid #bbbbbb;
    border-radius: 8px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    overflow: hidden;
    transition: box-shadow 0.3s ease;
    display: flex;
    flex-direction: column; /* This ensures children are aligned vertically */
    justify-content: space-around; /* This ensures space distribution among children */
  }
  
  #tax-assumptions-box:hover {
    box-shadow: 0px 4px 16px -3px rgba(0, 0, 0, 0.2);
  }
  
  @media (max-width: 899px) {
    #tax-assumptions-box {
      width: 90%;
      margin: 2% auto;
    }
  }

  #tax-assumptions-box-full-width {
    @extend #tax-assumptions-box;
    width: 92%;
    margin-left: 6%;
  }
  
  #tax-assumptions-box-full-width:hover {
    box-shadow: 0px 4px 16px -3px rgba(0, 0, 0, 0.2);
  }
  
  @media (min-width: 1201px) and (max-width: 1536px) {
    #tax-assumptions-box-full-width {
    width: 90%;
    margin-left: 8%;
   }
  }
  
  @media (min-width: 900px) and (max-width: 1200px) {
    #tax-assumptions-box-full-width {
    width: 88%;
    margin-left: 10%;
    }
  }

  @media (max-width: 899px) {
    #tax-assumptions-box-full-width {
      width: 90%;
      margin: 2% auto;
    }
  }

  .tax-assumptions-container {
    width: 96%;
  }
  
  .box-title {
    font-family: 'Poppins', sans-serif;
    text-align: center;
    font-weight: 700;
    font-size: 22px;
    margin-bottom: 1%;
    margin-top: 1%;
  }
  
  .tax-assumptions-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 4%; /* Space between grid items */
    row-gap: 60px;
    padding: 2%;
    font-size: 18px;
  }
  
  @media (min-width: 1125px ) and (max-width: 1624px) {
    .tax-assumptions-grid {
      grid-template-columns: repeat(2, 1fr);
      row-gap: 30px;
    }
  }
  
  @media (max-width: 1124px) {
    .tax-assumptions-grid {
      grid-template-columns: 1fr;
      row-gap: 20px;
    }
  }
  
  @media (min-width: 1625px ) and (max-width: 1700px) {
    .tax-assumptions-grid {
      font-size: 17px;
    }
  }
  
  @media (min-width: 1125px ) and (max-width: 1624px) {
    .tax-assumptions-grid {
      font-size: 17px;
      padding: 3%;
      margin-left: 4%;
    }
  }

  @media (min-width: 481px) and (max-width: 1124px) {
    .tax-assumptions-grid {
      margin-bottom: 4%;
      margin-left: 15%;
    }
  }
  
  @media (max-width: 480px) {
    .tax-assumptions-grid {
      font-size: 14px;
      margin-bottom: 10%;
      margin-left: 0;
    }
  }
  
  .tax-assumptions-grid-item {
    
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two child columns */
    gap: 5%; /* Space between child columns */
    justify-items: start;
  }
  
  .tax-assumptions-custom-margin {
    margin-bottom: 2%;
  }
  
  .tax-assumptions-label {
    font-weight: 500;
    font-family: 'Lato', sans-serif;
  }
  
  .tax-assumptions-select {
    font-family: 'Source Sans 3', sans-serif;
    background-color: #e6f4ff;
    border: 1px solid #0077b3;
    border-radius: 10%;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    padding: 0.5rem 1rem;
    line-height: 30px;
    font-size: 16px;
  }
  
  .tax-assumptions-select:focus {
    background-color: #ffffcc;
    border-color: #3f59e4;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(0,123,255,0.5);
  }
  
  @media (min-width: 769px) and (max-width: 1024px) {
    .tax-assumptions-select {
      padding: 8px 16px;
      line-height: 26px;
    }
  }
  
  @media (min-width: 400px) and (max-width: 768px) {
    .tax-assumptions-select {
      padding: 6px 12px;
      line-height: 24px;
    }
  }
  
  @media (max-width: 400px) {
    .tax-assumptions-select {
      padding: 4px 8px;
      line-height: 20px;
    }
  }