@import url('https://fonts.googleapis.com/css2?family=Lato:ital@0;1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz@6..12&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap');

body {
  background-color: #e6e6ea;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

.ref-navigation {
  padding-top: 0.1px;
}

.flex-container-reset-button {
  display: flex;
  position: relative;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 2%;
  width: 100%;
}

@media (min-width: 881px) and (max-width: 1124px) {
  .flex-container-reset-button {
    margin-bottom: 3%;
    margin-top: 3%;
    justify-content: center;
  }
}

@media (min-width: 461px) and (max-width: 880px) {
  .flex-container-reset-button {
    margin-top: 4%;
    margin-bottom: 4%;
    justify-content: center;
  }
}

@media (max-width: 460px) {
  .flex-container-reset-button {
    margin-top: 5%;
    margin-bottom: 5%;
    justify-content: center;
  }
}

.submit-button {
  background-color: lightblue;
  font-weight: 500;
  font-size: 150%;
  padding: 10%;
  border-radius: 10%;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  transition:
    background-color 0.3s,
    color 0.3s,
    border-color 0.3s;
  transform: translateX(-50%);
}

.submit-button:hover {
  background-color: #3f59e4;
  color: white;
  cursor: pointer;
}

.reset-button {
  font-family: 'Poppins', sans-serif;
  background-color: #e74c3c;
  color: white;
  font-size: 120%;
  font-weight: 600;
  padding: 1.25% 2%;
  border-radius: 5px;
  border: 3px solid #d35400;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition:
    background-color 0.3s,
    transform 0.2s;
  margin-right: 2%;
}

@media (min-width: 481px) and (max-width: 1124px) {
  .reset-button {
    padding: 2% 4%;
  }
}

@media (max-width: 480px) {
  .reset-button {
    padding: 4% 8%;
  }
}

.reset-button:hover {
  background-color: #d62c1a;
  border-color: #c0392b;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.reset-button:active {
  transform: translateY(1px);
}

.reset-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px #d62c1a;
}

/// Wonderful Button Transition CSS
// transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
// transition-property: background-color, box-shadow, border-color, color;
// transition-duration: 250ms, 250ms, 250ms, 250ms;
// transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1);
// transition-delay: 0ms, 0ms, 0ms, 0ms;
