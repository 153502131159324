.landing-page {
  background-color: white;
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}

.title-ls-container {
  background-color: #f0f8ff;
  /* Light blue background */
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  /* Add padding for better spacing */
}

@media (max-width: 900px) {
  .title-ls-container {
    border-bottom: 0;
    box-shadow: none;
  }
}

.title-ls {
  font-family: Arial, sans-serif;
  /* Change to a sans-serif font */
  text-align: center;
  color: #003366;
  /* Dark blue text color */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  margin: 0 auto;
  word-wrap: break-word;
  width: 90%;
  padding: 6% 0;
}

.title-ls h1 {
  line-height: 1.5;
  word-spacing: 5px;
  font-weight: 700;
}

/* Ensure the font size doesn't get too large on bigger screens */
@media (min-width: 960px) {
  .title-ls {
    font-size: 1.25rem;
    /* Fixed size for medium screens and up */
  }
}

@media (max-width: 960px) {
  .title-ls {
    padding: 12% 0;
  }
}

@media (max-width: 600px) {
  .title-ls {
    padding: 15% 0;
  }
}

@media (max-width: 960px) {
  .title-ls h1 {
    line-height: 1.7;
  }
}