.pitch-container {
    font-family: "Work Sans", sans-serif;
    background-color: #0047ab;
    color: white;
    padding: 5% 0;
    font-size: 20px;
}

.pitch-container-content {  
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: 76%;
  }
  
.pitch-container-content img {
    width: auto;
    height: auto;
    max-width: 420px;
    max-height: 280px;
    margin-right: 16px; /* Add space between the image and the text */
    border: 2px solid black;
    border-radius: 2%;
}
  
.pitch-container-content p {
    flex: 1; /* Allows paragraph to take the remaining width */
    margin-left: 16px; /* Adds space to the left of the paragraph */
    line-height: 1.5;
}

@media (min-width: 961px) and (max-width: 1280px) {
    .pitch-container-content {
      width: 90%;
    }
}
  
@media (max-width: 960px) {
    .pitch-container-content {
        width: 92%;
        flex-direction: column; /* Stack on small screens */
        margin-top: 20px;
    }
  
    .pitch-container-content img {
        margin-bottom: 5%;
    }
}

@media (max-width: 600px) {
    .pitch-container {
        padding: 15% 4%;
    }

    .pitch-container-content {
        width: 100%;
        flex-direction: column; /* Stack on small screens */
        margin-left: 0;
        align-items: center; 
    }
  
    .pitch-container-content img {
        max-width: 100%;
        height: auto;
        margin: 0 auto 5%;
    }

    .pitch-container-content p {
        word-wrap: break-word;
        margin-left: 0;
        padding-top: 10%;
    }
}