.login-drop-down-item {
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  -webkit-appearance: none;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  display: flex;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  text-decoration: none;
  padding-top: 6px;
  padding-bottom: 6px;
  box-sizing: border-box;
  white-space: nowrap;
  padding-left: 16px;
  padding-right: 16px;
}

.login-drop-down-item:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.04);
  width: 100%;
}

.user-profile-box-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
}

.user-profile-box {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  margin-left: 14%;
  width: 50%;
  max-width: 86%;
  letter-spacing: 0.5px;
  position: relative;
  background-color: #ffffff;
  color: #333;
  padding: 2%;
  border-radius: 5px;
  border: 1px solid black;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 4;
}

@media (min-width: 481px) and (max-width: 900px) {
  .user-profile-box {
    margin-left: 0;
    width: 70%;
    max-width: 70%;
  }
}

@media (max-width: 480px) {
  .user-profile-box {
    margin-left: 0;
    width: 90%;
    max-width: 90%;
  }
}

.user-profile-box h3 {
  font-weight: 600;
  font-size: 150%;
  margin-bottom: 3%;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #333;
}

.user-details {
  margin-bottom: 4%;
}

.user-details p {
  font-size: 110%;
  margin-bottom: 1.5%;
  color: #666;
  overflow-wrap: break-word;
}

.user-details span {
  color: #333;
  font-weight: 600;
}

.user-details ul {
  list-style-type: none;
  padding-left: 3%;
}

.user-details ul li {
  font-size: 100%;
  color: #666;
  margin-bottom: 1.5%;
}

.user-profile-box-button-group {
  display: flex;
  justify-content: center;
  margin-top: 1%;
}

.user-profile-box-button-group button {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 800;
  background-color: lightblue;
  color: #333333;
  padding: 10px 20px;
  border: 3px solid darken(lightblue, 10%);
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition-duration: 0.4s;
  transition: background-color 0.3s, box-shadow 0.3s, border 0.3s, outline 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  outline: none;
}

.user-profile-box-button-group button:hover {
  background-color: darken(lightblue, 5%);
  border: 3px solid darken(lightblue, 15%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.user-profile-box-button-group button:focus {
  outline: 3px solid lighten(lightblue, 10%);
}

.confirmation-box-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}

.confirmation-box {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: black;
  background-color: white;
  margin-left: 14%;
  width: 50%;
  max-width: 86%;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  border: 1px solid black;
}

@media (min-width: 481px) and (max-width: 900px) {
  .confirmation-box {
    margin-left: 0;
    width: 70%;
    max-width: 70%;
  }
}

@media (max-width: 480px) {
  .confirmation-box {
    margin-left: 0;
    width: 90%;
    max-width: 90%;
  }
}

.confirmation-box p {
  font-size: 17px;
  letter-spacing: .25px;
}

.login-drop-down-button-group {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.login-drop-down-button-group button {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 800;
  color: #333333;
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  padding: 10px 20px;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition-duration: all 0.4s;
  transition: background-color 0.3s, box-shadow 0.3s, border 0.3s, outline 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  outline: none;
}

.login-drop-down-button-group-confirmation button {
  background-color: lightblue;
  border: 3px solid darken(lightblue, 10%);
}

.login-drop-down-button-group-confirmation button:hover {
  background-color: darken(lightblue, 5%);
  border: 3px solid darken(lightblue, 15%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.login-drop-down-button-group-confirmation button:focus {
  outline: 3px solid lighten(lightblue, 10%);
}

.login-drop-down-button-group-cancel button {
  background-color: #a9a9a9;
  border: 3px solid darken(#a9a9a9, 10%);
}

.login-drop-down-button-group-cancel button:hover {
  background-color: darken(#a9a9a9, 5%);
  border: 3px solid darken(#a9a9a9, 15%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.login-drop-down-button-group-cancel button:focus {
  outline: 3px solid lighten(#a9a9a9, 10%);
}

.login-drop-down-button-group-load button {
  background-color: #4CAF50;
  border: 3px solid darken(#4CAF50, 10%);
}

.login-drop-down-button-group-load button:hover {
  background-color: darken(#4CAF50, 5%);
  border-color: darken(#4CAF50, 15%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.login-drop-down-button-group-load button:focus {
  outline: 3px solid lighten(#4CAF50, 10%);
}

.login-drop-down-button-group-delete button {
  background-color: #e57373;
  border: 3px solid darken(#e57373, 10%);
}

.login-drop-down-button-group-delete button:hover {
  background-color: darken(#e57373, 5%);
  border-color: darken(#e57373, 15%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.login-drop-down-button-group-delete button:focus {
  outline: 3px solid lighten(#e57373, 10%);
}

.data-exchange-box-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  z-index: 3;
}

.data-exchange-box {
  color: black;
  background-color: #ffffff;
  margin-left: 14%;
  width: 50%;
  max-width: 86%;
  padding: 60px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  border: 1px solid black;
}

@media (min-width: 901px) and (max-width: 1200px) {
  .data-exchange-box {
    margin-left: 14%;
    width: 86%;
    max-width: 86%;
    padding: 60px;
  }
}

@media (min-width: 481px) and (max-width: 900px) {
  .data-exchange-box {
    margin-left: 0;
    width: 80%;
    max-width: 80%;
    padding: 60px 40px;
  }
}

@media (max-width: 480px) {
  .data-exchange-box {
    margin-left: 0;
    width: 90%;
    max-width: 90%;
    padding: 60px 10px;
  }
}

.data-exchange-box-group {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  letter-spacing: 0.25px;
  font-size: 17px;
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

@media (max-width: 864px) {
  .data-exchange-box-group {
    flex-wrap: wrap;
  }
}

.data-exchange-box-label {
  padding-left: 5px;
  font-size: 20px;
  font-family: 'Lato', sans-serif;
}

@media (max-width: 864px) {
  .data-exchange-box-label {
    margin-bottom: 5%;
    display: flex;
    flex-direction: column;
  }
}

.data-exchange-box-input {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 20px;
  padding: 10px;
  width: 60%;
  background-color: #f8f8f8;
  border-radius: 5px;
  border: 2px solid #007bff;
  box-sizing: border-box;
  box-shadow: 0px 10px 10px -7px rgba(0, 0, 0, 0.5);
  transition:
    border-color 0.3s ease-in-out,
    box-shadow 0.3s ease-in-out;
}

@media (min-width: 481px) and (max-width: 900px) {
  .data-exchange-box-input {
    padding: 12px;
    width: 90%;
  }
}

@media (max-width: 480px) {
  .data-exchange-box-input {
    padding: 12px;
    width: 90%;
  }
}

.data-exchange-box-input:focus {
  outline: none;
  border-color: #0056b3;
  box-shadow: 0 0 8px 0 rgba(0, 123, 255, 0.5);
}

#data-exchange-group-buttons {
  margin-top: 7%;
}