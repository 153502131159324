.safe-harbor-input-container {
  width: 82%;
  margin-left: 16%;
  margin-top: 2%;
  padding: 1% 0%;
  display: flex;
  background-color: #ffffff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid #dddddd;
  border-bottom: 4px solid #bbbbbb;
  border-radius: 8px;
  box-shadow:
    0 1px 6px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  overflow: hidden;
  transition: box-shadow 0.3s ease;
}

.safe-harbor-input-container:hover {
  box-shadow: 0px 4px 16px -3px rgba(0, 0, 0, 0.2);
}

@media (max-width: 899px) {
  .safe-harbor-input-container {
    width: 90%;
    margin-left: 5%;
  }
}

@media (max-width: 480px) {
  .safe-harbor-input-container {
    font-size: 12px;
  }
}

.safe-harbor-input-container-full-width {
  @extend .safe-harbor-input-container;
  width: 92%;
  margin-left: 6%;
}

.safe-harbor-input-container-full-width:hover {
  box-shadow: 0px 4px 16px -3px rgba(0, 0, 0, 0.2);
}

@media (min-width: 1201px) and (max-width: 1536px) {
  .safe-harbor-input-container-full-width {
  width: 90%;
  margin-left: 8%;
 }
}

@media (min-width: 900px) and (max-width: 1200px) {
  .safe-harbor-input-container-full-width {
  width: 88%;
  margin-left: 10%;
  }
}

@media (max-width: 899px) {
  .safe-harbor-input-container-full-width {
    width: 90%;
    margin-left: 5%;
  }
}

.safe-harbor-input-grid-container {
  width: 100%;
}

.safe-harbor-input-grid {
  width: auto;
  margin-bottom: 2%;
  padding: 3%;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
  border-radius: 5%;
  transition: background-color 0.3s ease;
}

.safe-harbor-input-grid-item {
  font-family: 'Lato', sans-serif;
  text-align: center;
  font-weight: 700;
  padding: 12px;
  margin: 10px 0;
  transition:
    transform 0.3s ease-in-out,
    box-shadow 0.3s ease-in-out;
  border: 1px solid #b0bec5;
  border-radius: 10px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 22px); /* Full width minus padding and border */
}

.safe-harbor-input-grid-item-input {
  @extend .safe-harbor-input-grid-item;
  transition:
    transform 0.3s ease-in-out,
    box-shadow 0.3s ease-in-out;
  font-weight: 400;
}

.safe-harbor-input-grid-item-input:hover {
  box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.2);
}

.safe-harbor-select-field {
  font-family: 'Source Sans 3', sans-serif;
  width: 92%;
  line-height: 30px;
  font-size: 16px;
  background-color: #e6f4ff;
  border: 1px solid #0077b3;
  border-radius: 10%;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
    padding: 0.5rem 1rem;
}

@media (min-width: 401px) and (max-width: 500px) {
  .safe-harbor-select-field {
    padding: 10px 15px;
  }
}

@media (min-width: 351px) and (max-width: 400px) {
  .safe-harbor-select-field {
    padding: 10px;
  }
}

@media (max-width: 350px) {
  .safe-harbor-select-field {
    padding: 10px 5px;
  }
}

.safe-harbor-select-field:focus {
  background-color: #ffffcc;
  border-color: #3f59e4;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0,123,255,0.5);
}

.safe-harbor-input-field {
  font-family: 'Nunito Sans', sans-serif;
  background-color: #f8f8f8;
  width: 88%;
  display: block;
  padding: 8px 8px;
  height: 22px;
  line-height: 10px;
  font-size: 17px;
  background-clip: padding-box;
  border: 1px solid #babec5;
  border-radius: 3px;
  box-shadow: 0;
  transition: none;
}

@media (max-width: 1024px) {
  .safe-harbor-input-field {
    width: 84%;
  }
}

@media (min-width: 697px) and (max-width: 1024px) {
  .safe-harbor-input-field {
    width: 84%;
  }
}

@media (max-width: 696px)  {
  .safe-harbor-input-field {
    width: 80%;
  }
}


.safe-harbor-input-field:focus {
  border-color: #3f59e4;
  outline: 0;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
}
