.side-bar-toggle-container {
  text-align: left;
  margin-left: 10px;
  margin-top: 5px;
  position: fixed;
}

.side-bar-toggle-button {
  font-family: 'Poppins', sans-serif;
  background-color: #0047ab;
  color: white;
  font-size: 18px;
  font-weight: 800;
  padding: 10px 20px;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border: 3px solid darken(#0047ab, 10%);
  transition-duration: all 0.4s;
  transition:
    background-color 0.3s,
    box-shadow 0.3s,
    border 0.3s,
    outline 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  outline: none;
}

.side-bar-toggle-button:hover {
  background-color: darken(#0047ab, 5%);
  border: 3px solid darken(#0047ab, 15%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.side-bar-toggle-button:focus {
  outline: 3px solid lighten(#0047ab, 10%);
}

@media (max-width: 899.98px) {
  .side-bar-toggle-container {
    display: none;
  }
}