#client-title-box {
  background-color: #ffffff;
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  text-align: center;
  width: 82%;
  margin-left: 16%;
  margin-top: 1%;
  padding: 3% 0%;
  border: 2px solid #dddddd;
  border-radius: 8px;
  box-shadow:
    0 1px 6px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  transition: box-shadow 0.3s ease;
}

#client-title-box:hover {
  box-shadow: 0px 4px 16px -3px rgba(0, 0, 0, 0.2);
}

@media (min-width: 601px) and (max-width: 899px) {
  #client-title-box {
    width: 90%;
    margin-left: 5%;
    grid-template-columns: 1fr;
    padding: 4% 0%;
  }
}

@media (max-width: 600px) {
  #client-title-box {
    width: 90%;
    margin-left: 5%;
    grid-template-columns: 1fr;
    padding: 6% 0%;
  }
}

#client-title-box-full-width {
  @extend #client-title-box;
  width: 92%;
  margin-left: 6%;
}

#client-title-box-full-width:hover {
  box-shadow: 0px 4px 16px -3px rgba(0, 0, 0, 0.2);
}

@media (min-width: 1201px) and (max-width: 1536px) {
  #client-title-box-full-width {
    width: 90%;
    margin-left: 8%;
  }
}

@media (min-width: 900px) and (max-width: 1200px) {
  #client-title-box-full-width {
    width: 88%;
    margin-left: 10%;
  }
}

@media (min-width: 601px) and (max-width: 899px) {
  #client-title-box-full-width {
    width: 90%;
    margin-left: 5%;
    grid-template-columns: 1fr;
    padding: 4%;
  }
}

@media (max-width: 600px) {
  #client-title-box-full-width {
    width: 90%;
    margin-left: 5%;
    grid-template-columns: 1fr;
    padding: 6%;
  }
}

#client-title-box-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1px;
  align-items: center;
  padding: 0% 2%;
}

@media (max-width: 900px) {
  #client-title-box-grid {
    grid-template-columns: 1fr;
  }
}

.client-title-box-grid-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 5px;
}

@media (min-width: 601px) and (max-width: 900px) {
  .client-title-box-grid-item {
    flex-direction: column;
    gap: 1px;
    margin-bottom: 25px;
  }
}

@media (max-width: 600px) {
  .client-title-box-grid-item {
    flex-direction: column;
    gap: 1px;
    margin-bottom: 25px;
  }
}

.client-input-box {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 20px;
  padding: 14px;
  width: 60%;
  background-color: #f8f8f8;
  border-radius: 5px;
  border: 2px solid #007bff;
  box-sizing: border-box;
  box-shadow: 0px 10px 10px -7px rgba(0, 0, 0, 0.5);
  transition:
    border-color 0.3s ease-in-out,
    box-shadow 0.3s ease-in-out;
  /* Smooth transition for interactions */
}

@media (min-width: 601px) and (max-width: 900px) {
  .client-input-box {
    width: 70%;
  }
}

@media (max-width: 600px) {
  .client-input-box {
    width: 100%;
  }
}

.client-input-box:focus {
  outline: none;
  border-color: #0056b3;
  box-shadow: 0 0 8px 0 rgba(0, 123, 255, 0.5);
}

// temporary until I can find better backend hosting or pay for current backend hosting ---below
.client-title-box-blurb {
  margin-top: 2%;
  font-size: 16px;
  font-weight: 500;
}

#client-title-box-blurb-disconnected {
  color: red;
}

#client-title-box-blurb-connected {
  color: blue;
}

.client-title-box-blurb-loading {
  animation: colorchange 2s infinite;
}

@keyframes colorchange {
  0% {
    color: lightgreen;
  }

  100% {
    color: darkgreen;
  }
}

// temporary until I can find better backend hosting or pay for current backend hosting ---above