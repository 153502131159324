.footer-container-full-width {
  width: 100%;
  margin: 4% 0 0 0;
  background-color: #e8f0fe;
  color: #333;
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.1);
  padding: 10px 0 0 0;
}

.footer-container {
  width: 86%;
  margin: 4% 0 0 14%;
  background-color: #e8f0fe;
  color: #333;
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.1);
  padding: 10px 0 0 0;
}

@media (max-width: 900px) {
  .footer-container {
    width: 100%;
    margin-left: 0;
  }
}

.footer-contact-container {
  font-family: 'Poppins', sans-serif;
  line-height: 200%;
  padding: 5% 0 0 0;
  font-size: 25px;
  margin: auto;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  color: #333;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

@media (max-width: 600px) {
  .footer-contact-container {
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    line-height: 180%;
    font-size: 20px;
  }
}

.footer-column {
  flex-basis: 30%;
  min-width: 200px; // prevents columns from getting too narrow
  padding: 20px;

  h1 {
    font-weight: 600;
    font-size: 30px;
    margin-bottom: 20px;
    color: #0047ab; // Match the link color
  }

  a {
    color: #0047ab;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
}

@media (max-width: 960px) {
  .footer-column {
    flex-basis: 80%;
  }
}

@media (max-width: 600px) {
  .footer-column {
    flex-basis: 96%;
  }
}

.footer-social-icons {
  display: inline-block;
  align-items: center;
  margin-right: 20px;
  text-decoration: none;
  transition: color 0.3s;

  .MuiSvgIcon-root {
    font-size: 50px;
    color: #0047ab;
    transition: transform 0.3s ease-in-out;
  }

  &:hover {
    .MuiSvgIcon-root {
      transform: scale(1.2);
      /* Example transform, you can customize */
      color: black;
    }
  }
}

.footer-contact-container h1 {
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 10px;
}

.footer-contact-container a {
  color: #0047ab;
  text-decoration: none;
}

.footer-contact-container a:hover {
  text-decoration: underline;
}

.footer-info-container {
  font-family: 'Source Sans 3', sans-serif;
  font-size: 15px;
  background-color: #0047ab;
  color: white;
  width: 100%;
  margin-top: 80px;
  padding: 1% 0;
  text-align: center;
}

@media (max-width: 960px) {
  .footer-info-container {
    padding: 2% 0;
  }
}

@media (max-width: 600px) {
  .footer-info-container {
    padding: 3% 0;
  }
}

.footer-disclaimer h3 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  margin: 1%;
}

#footerContact p,
.footer-info p {
  margin: 0;
  padding: 0;
}

.footer-disclaimer {
  padding: 0% 1% 1% 1%;
}

@media (max-width: 600px) {
  .footer-disclaimer {
    padding: 3%;
  }
}

.footer-disclaimer p {
  text-align: left;
}

.footer-disclaimer-link {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.footer-disclaimer-link:hover {
  text-decoration: underline;
}